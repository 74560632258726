





























































import Vue, { PropType } from 'vue'
import {
  mapActions
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default Vue.extend({
  name: 'ModalAddImage',

  components: {
    Loading,
    CircleStencil,
    Cropper
  },

  props: {
    title: {
      type: String as PropType<string>,
      default: 'Загрузка изображения'
    },

    maxWidth: {
      type: Number as PropType<number>,
      default: 1920
    },

    maxHeight: {
      type: Number as PropType<number>,
      default: 1080
    },

    stencil: {
      type: String as PropType<string>,
      default: 'rectangle'
    },

    value: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data () {
    return {
      showed: false,
      loading: false,
      image: {
        src: null,
        type: null
      }
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  methods: {
    ...mapActions('common', [
      'uploadMedia'
    ]),

    defaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },

    onModalHidden () {
      this.$emit('modal-hide')
      this.image.src = null
      this.image.type = null
    },

    cropAndUpload () {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()

        canvas.toBlob(blob => {
          formData.append('file_to_upload', blob)
          this.handleUploadMedia(formData)
        }, this.image.type)
      }
    },

    async handleUploadMedia (formData) {
      try {
        this.loading = true
        const res = await this.uploadMedia(formData)
        this.$emit('image-uploaded', res.url)
      } catch (error) {
        this.$toast.error(error.message)
        this.$bvModal.hide('modal-add-image')
      } finally {
        this.loading = false
      }
    },

    reset () {
      this.image = {
        src: null,
        type: null
      }
    },

    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''

      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }

      switch (header) {
        case '89504e47':
          return 'image/png'
        case '47494638':
          return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },

    loadImage (event) {
      // Reference to the DOM input element
      const { files } = event.target

      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }

        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.getMimeType(e.target.result, files[0].type)
          }

          // this.setCoordinates()
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0])
      }
    }

    // setCoordinates () {
    //   console.log(this.$refs.cropper)
    //   this.$refs.cropper.setCoordinates((coordinates, imageSize) => ({
    //     width: imageSize.width,
    //     height: imageSize.height
    //   }))
    // }
  }
})
